<template>
  <div class="select__list__checkbox highlight row">
    <div class="checkbox row">
      <i v-if="selected" class="fa-solid fa-check"></i>
    </div>

    <span
      class="nowrap"
      :title="property === 'email' || emailRegex.test(value) ? `${value}` : `${upperCase(value)}`"
    >
      {{ t(property === 'email' || emailRegex.test(value) ? value : upperCase(value)) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CheckboxRow',
  props: {
    selected: { type: Boolean, default: false },
    value: { type: String, default: '' },
    property: { type: String, default: '' },
  },
  setup() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return {
      emailRegex,
    }
  },
}
</script>

<style lang="scss" scoped>
.select__list__checkbox {
  align-items: center;
  padding: 0 10px;
  border-radius: 15px;
  cursor: pointer;
  height: 45px;

  .checkbox {
    border: 1px solid var(--dark-10);
    min-width: 25px;
    min-height: 25px;
    margin-right: 10px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
  }
}
</style>
