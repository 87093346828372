export default {
  'Add Report': 'Report hinzufügen',
  'Edit Report': 'Report bearbeiten',
  'api-key-instruction':
    'API-Schlüssel werden ausschließlich auf dem Server gespeichert und können durch eine Anfrage an unseren Validierungs-Endpunkt überprüft werden.',
  'Add Component': 'Komponente hinzufügen',
  'Report component': 'Berichtskomponente',
  Url: 'Url',
  Type: 'Typ',
  Width: 'Breite',
  'Request Headers': 'Request Headers',
  'Request Params': 'Request Params',
  'Delete Report?': 'Report löschen?',
  Description: 'Beschreibung',
  Date: 'Datum',
  From: 'Von',
  To: 'Bis',
  'From Comp.': 'Von vergl.',
  'To Comp.': 'Bis vergl.',
  'Date Range': 'Datum Zeitraum',
  'Date Range Comp.': 'Datum Zeitraum Vergl.',
  Apply: 'Anwenden',
  'Existing Filter Lists': 'Existing Filter Lists',
  'Filter Lists': 'Filterlisten',
  Lists: 'Listen',
  'Manage your Filter Lists': 'Filterlisten verwalten',
  'X-Axis Title': 'Titel X-Achse',
  'Y-Axis Title': 'Titel Y-Achse',
  'Y-Axis Symbol': 'Symbol Y-Achse',
  Legend: 'Legende',
  'X-Axis Labels': 'X-Achsen-Beschr.',
  'Y-Axis Labels': 'Y-Achsen-Beschr.',
  'Data Labels': 'Daten-Label',
  'Stack Labels': 'Label stapeln',
  'Inside Labels': 'Innere Label',
  'Line Marker': 'Linienmarkierung',
  'Show Footer': 'Fußzeile anzeigen',
  'Small Rows': 'Schmale Zeilen',
  'Configure columns': 'Spalten konfigurieren',
}
