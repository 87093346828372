import { createStore } from 'vuex'
import config from '@config'

let os = 'web'
if (
  navigator.userAgent.includes('Android') ||
  navigator.userAgent.includes('iOS') ||
  navigator.userAgent.includes('iPhone') ||
  navigator.userAgent.includes('iPad')
)
  os = 'mobile'

let url = config.URL
let mode = 'live'
if (window.location.href.includes('http://localhost:8080')) {
  url = `http://localhost:${config.BACKEND_PORT}`
  mode = 'development'
} else if (window.location.href.includes('http://192.168.178.26:8080')) {
  url = `http://192.168.178.26:${config.BACKEND_PORT}`
  mode = 'development'
} else if (window.location.href.includes('http://demo.localhost:8080')) {
  url = `http://localhost:${config.BACKEND_PORT}`
  mode = 'demo'
}

let language = 'en'
let supportedLanguages = ['en', 'de']
let userLanguage = navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0]
if (supportedLanguages.includes(userLanguage)) language = userLanguage

export default createStore({
  modules: {},

  state() {
    return {
      url: url,
      mode: mode,
      os: os,
      token: null,
      tokenExpirationDate: null,
      language: language,
      user: { id: null },
      dashboardNav: { expanded: window.innerWidth > 1000 ? true : false },
      overlay: { show: false },
      deleteOverlay: { show: false },
      loading: { show: false, timeout: null, id: '' },
      notification: { show: false, messages: [] },
      screen: {
        w: 0,
        h: 0,
        orientation: '',
        device: '',
        keyboardOpen: false,
        rotationWarning: false,
      },
    }
  },

  mutations: {
    setLanguage(state, lang) {
      if (supportedLanguages.includes(lang)) state.language = lang
      else state.language = 'en'
    },

    calcScreen(state) {
      const width = window.innerWidth
      const height = window.innerHeight

      const availHeight = window.screen.availHeight
      const availWidth = window.screen.availWidth

      let keyboardOpen = false
      if (os === 'mobile' && height / availHeight < 0.7) keyboardOpen = true

      let orientation = 'landscape'
      if (os === 'web') orientation = width > height ? 'landscape' : 'portrait'
      else if (os === 'mobile') orientation = availWidth > availHeight ? 'landscape' : 'portrait'

      let device = 'desktop'

      if (os === 'mobile') {
        if (orientation === 'portrait') {
          if (width <= 450) device = 'mobile'
          else device = 'tablet'
        } else if (keyboardOpen) {
          if (availHeight <= 480) device = 'mobile'
          else device = 'tablet'
        } else {
          if (height <= 480 && width <= 1100) device = 'mobile'
          else device = 'tablet'
        }
      }

      let rotationWarning = device === 'mobile' && orientation === 'landscape'

      state.screen.w = width
      state.screen.height = height
      state.screen.orientation = orientation
      state.screen.device = device
      state.screen.keyboardOpen = keyboardOpen
      state.screen.rotationWarning = rotationWarning
    },

    setUser(state, user) {
      state.user.id = user._id
      state.user = { ...state.user, ...user }
    },

    setLoadingId(state, id = '') {
      if (state.loading.show) {
        setTimeout(() => {
          clearTimeout(state.loading.timeout)

          state.loading.id = ''
          state.loading.show = false
        }, 500)
      } else {
        state.loading.timeout = setTimeout(() => {
          state.loading.show = true
          state.loading.id = id
        }, 250)
      }
    },

    unsetLoading(state) {
      clearTimeout(state.loading.timeout)

      state.loading.id = ''
      state.loading.show = false
    },

    showNotification(state, notification) {
      state.notification.show = true

      let created = new Date().getTime()

      state.notification.messages.push({
        type: notification.type,
        text: notification.message,
        created: created,
      })

      state.notification.messages.sort((a, b) => {
        return a.created - b.created
      })

      setTimeout(() => {
        if (state.notification.messages.length > 0)
          for (let [i, n] of state.notification.messages.entries()) {
            if (n.created === created) state.notification.messages.splice(i, 1)
          }
        else state.notification.show = false
      }, 5000)
    },

    hideNotification(state, notification) {
      for (let [i, n] of state.notification.messages.entries()) {
        if (n.created === notification.created) state.notification.messages.splice(i, 1)
      }

      if (state.notification.messages.length <= 0) state.notification.show = false
    },

    showOverlay(state, show) {
      state.overlay.show = show
    },

    showDeleteOverlay(state, show) {
      state.deleteOverlay.show = show
    },

    expandDarboardNav(state, show) {
      state.dashboardNav.expanded = show
    },
  },

  actions: {
    initScreenListener({ commit }) {
      commit('calcScreen')
      window.addEventListener('resize', () => commit('calcScreen'))
    },

    async init({ state, commit, dispatch }) {
      const user = await dispatch('getUser', state.user.id)
      if (!user) return await dispatch('logout')

      commit('setUser', user)

      await Promise.all([
        await dispatch('getOrganization'),
        await dispatch('getUserSettings'),
        await dispatch('getReports'),
        await dispatch('getFilterLists'),
        await dispatch('getUsers'),
        await dispatch('getTeams'),
        await dispatch('getRoles'),
      ])

      commit('setLanguage', state.settings.general.language)
    },

    /*
      ROUTE /demo/
    */
    async startDemo({ commit, rootState }) {
      const url = `${rootState.url}/demo`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success && data.user && data.token && data.expirationDate) {
        commit('showNotification', { type: 'info', message: data.success })
        commit('setUserData', {
          rootState,
          user: data.user,
          token: data.token,
          expirationDate: data.expirationDate,
        })
      }
    },
  },
})
