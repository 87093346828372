<template>
  <button class="button row" :id="id" :class="[...classes]" type="button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'SlotBtn',
  props: {
    classes: { type: Array, default: [] },
  },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
