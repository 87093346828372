<template>
  <button class="button row" :id="id" :class="[...classes]" type="button">
    <span>
      {{ t(text) }}
    </span>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: { type: String, default: 'Cancel' },
    classes: { type: Array, default: [] },
  },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
