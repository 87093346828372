export default {
  General: 'General',
  'Edit Profile': 'Edit Profile',
  Logout: 'Logout',
  Teams: 'Teams',
  Roles: 'Roles',
  'Add new user': 'Add new user',
  'Edit User': 'Edit User',
  'Edit organization': 'Edit organization',
  'Subscription management': 'Subscription management',
  Back: 'Back',
  Next: 'Next',
  Language: 'Language',
  'Color Mode': 'Color Mode',
  English: 'English',
  German: 'German',
  Light: 'Light',
  Dark: 'Dark',
  Dashboard: 'Dashboard',
  'Show Users List': 'Show Users List',
  On: 'On',
  Off: 'Off',
  'Existing Teams': 'Existing Teams',
  Tracking: 'Tracking',
  'Auto time tracking': 'Auto time tracking',
  'If set off, changing your status will not create a time entry.':
    'If set off, changing your status will not create a time entry.',
  'Auto status update': 'Auto status update',
  'If set off, adding a time entry will not update your status.':
    'If set off, adding a time entry will not update your status.',
  'Add a new User': 'Add a new User',
  Email: 'Email',
  'Password*': 'Password*',
  Role: 'Role',
  Update: 'Update',
  'Admin permission': 'Admin permission',
  'Human Resource': 'Human Resource',
  'New Password': 'New Password',
  'Repeat Password': 'Repeat Password',
  Information: 'Information',
  'e.g. "Out for summer break."': 'e.g. "Out for summer break."',
  'Weekly hours': 'Weekly hours',
  'Annual leave': 'Annual leave',
  'Work days': 'Work days',
  Username: 'Username',
  'First name': 'First name',
  'Last name': 'Last name',
  'Zip Code': 'Zip Code',
  City: 'City',
  Street: 'Street',
  Number: 'Number',
  Country: 'Country',
  Phone: 'Phone',
  Mobile: 'Mobile',
  Skills: 'Skills',
  Save: 'Save',
  'Manage your Teams': 'Manage your Teams',
  'Manage your Roles': 'Manage your Roles',
  'Team name': 'Team name',
  Manager: 'Manager',
  'Edit organization details': 'Edit organization details',
  Name: 'Name',
  Owner: 'Owner',
  'Organization Roles': 'Organization Roles',
  'Role name': 'Role name',
  'Manage your subscription': 'Manage your subscription',
  'Subscription started': 'Subscription started',
  'Past invoices': 'Past invoices',
  'Current plan': 'Current plan',
  'Billing circle started': 'Billing circle started',
  'Billing circle ends': 'Billing circle ends',
  'Subscription interval': 'Subscription interval',
  'Billing amount': 'Billing amount',
  Canceled: 'Canceled',
  'Access till': 'Access till',
  'Upgrade subscription': 'Upgrade subscription',
  'Manage subscription': 'Manage subscription',
  'Trial expiration date': 'Trial expiration date',
  'Learn more': 'Learn more',
  Startup: 'Startup',
  Basic: 'Basic',
  Premium: 'Premium',
  Corporate: 'Corporate',
  '(e.g. Development, Excel)': '(e.g. Development, Excel)',
  'Repeat Email': 'Repeat Email',
  Permission: 'Permission',
  'Team & Role': 'Team & Role',
  'Note of absence': 'Note of absence',
  'General Information': 'General Information',
  Worktime: 'Worktime',
  'Delete User?': 'Delete User?',
  Users: 'Users',
  'Delete Organization?': 'Delete Organization?',
  'Existing Roles': 'Existing Roles',
  'Generel Settings': 'Generel Settings',
}
