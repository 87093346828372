import { createApp } from 'vue'
import { MotionPlugin } from '@vueuse/motion'
import global from '@lib/global.js'
import router from './router'
import store from './store'
import App from './App.vue'
import './registerServiceWorker'

// importing triggers the registration functions in modules index.js
import authentication from '@modules/authentication'
import dashboard from '@modules/dashboard'
import settings from '@modules/settings'
import onboarding from '@modules/onboarding'
import reports from '@modules/reports'

const app = createApp(App)

app.use(MotionPlugin)
app.use(store)
app.use(router)
app.use(global)

app.mount('#app')
