export default {
  'Get started for free': 'Jetzt kostenlos starten',
  'Sign up': 'Jetzt starten',
  'Welcome Back': 'Willkommen zurück',
  "Don't have an account?": 'Noch kein Account vorhanden?',
  '(min. 4 letters)': '(min. 4 Buchstaben)',
  'Already have an account?': 'Bereits ein Account?',
  'Reset Password': 'Passwort zurücksetzen',
  'Verify Account': 'Account verifizieren',
  'Verification Code': 'Verifizierungscode',
  'Account already verified?': 'Account bereits verifiziert?',
  Password: 'Passwort',
  'Forgot password': 'Passwort vergessen',
  'Enter your email address': 'Wie ist deine E-Mail?',
  Submit: 'Senden',
  Login: 'Login',
  Organization: 'Unternehmen',
  'Min. char': 'Min. Char',
  Uppercase: 'Groß',
  Number: 'Nummer',
  Special: 'Sonderz.',
  Start: 'Start',
  Email: 'Email',
  'demo-01':
    'Bitte beachten: Alle Daten, die in einem Demokonto generiert werden, werden nicht dauerhaft gespeichert.',
  Login: 'Login',
  Language: 'Sprache',
  German: 'Deutsch',
  English: 'Englisch',
  'Legal notice': 'Impressum',
  'Contact us': 'Kontakt',
  Verify: 'Verifizieren',
  'Select language': 'Sprache wählen',
  'Start now': 'Starte jetzt',
  'A verification email has been sent to:': 'Eine Bestätigungs-E-Mail wurde gesendet an:',
  'Request a new verification code': 'Neuen Bestätigungscode anfordern',
  OR: 'ODER',
  'Sign in with Google': 'Mit Google anmelden',
  'Creating powerful reports made easy': 'Leistungsstarke Berichte ganz einfach erstellen',
  'Create an account to get started with your 7-day trial.':
    'Erstelle ein Konto und starte deine 7-tägige Testphase.',
  'Simple, intuitive data structures': 'Einfache, intuitive Datenstrukturen',
  'Simple & Pivot Tables': 'Einfache & Pivot-Tabellen',
  'Pie, Column, Areaspline charts, and more': 'Kreis-, Säulen-, Flächendiagramme und mehr',
  'Filter and export functionality': 'Filter- und Exportfunktion',
  'Permission system': 'Berechtigungssystem',
  '...and much more': '...und vieles mehr',
}
