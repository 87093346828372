export const filterUserList = (users, filter) => {
  let filtered = users.filter((u) => {
    if (
      u.email.includes(filter) ||
      u?.team?.name.toLowerCase().includes(filter) ||
      u?.role?.name.toLowerCase().includes(filter)
    )
      return u
  })

  return filtered
}
