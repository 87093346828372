<template>
  <div class="list__entry row">
    <div
      v-for="[key, value] of Object.entries(entry)"
      :key="key"
      :style="{ width: `${100 / Object.keys(entry).filter((k) => k !== 'id').length}%` }"
      class="entry nowrap"
      :class="{ hidden: key === 'id' }"
    >
      <span :title="value">
        {{ value }}
      </span>
    </div>

    <Options
      v-if="options.length > 0"
      :options="options"
      :scrollContainer="scrollContainer"
      @optionClick="$emit('optionClick', $event)"
    />
  </div>
</template>

<script>
import Options from '@components/options/Options'

export default {
  name: 'ListEntry',
  components: { Options },
  emits: ['optionClick'],
  props: {
    entry: { type: Object, default: {} },
    columns: { type: Array, default: [] },
    options: { type: Array, default: [] },
    scrollContainer: { type: String, default: '' },
  },
}
</script>

<style lang="scss" scoped>
.list__entry {
  margin-bottom: 5px;
  padding: 3px 0;
  justify-content: space-between;

  .entry {
    min-width: 100px;
  }
}
</style>
