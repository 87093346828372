import { createRouter, createWebHistory } from 'vue-router'
import { hasPermission } from '@modules/authentication/helper.js'
import store from '../store/index.js'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/auth/login',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async function (to, from, next) {
  if (store.state.deleteOverlay.show) {
    store.commit('showDeleteOverlay', false)
    return next(false)
  }

  if (store.state.overlay.show) {
    store.commit('showOverlay', false)
    return next(false)
  }

  if (store.state.dashboardNav.expanded && store.state.screen.w < 1100) {
    store.commit('expandDarboardNav', false)
  }

  if (['Login', 'Register', 'Verify'].includes(to.name) && store.state.mode === 'demo') {
    next('/auth/demo')
  } else if (store.state.mode !== 'demo' && to.name === 'Demo') {
    next('/auth/login')
  }

  document.title = to.meta.title

  if (to.meta.authenticated && !store.state.user.id && !localStorage.getItem('user')) {
    next('/')
  } else if (to.meta.unAuthenticated && store.state.user.id) {
    next('/dashboard')
  } else if (to.meta.permissions) {
    if (!store.state.user?.permission) {
      const res = await store.dispatch('getUser', store.state.user.id)
      store.commit('setUser', res)
    }

    if (hasPermission(to.meta.permissions, to.params)) next()
    else next('/')
  } else {
    next()
  }
})

export default router
