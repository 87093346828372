<template>
  <div
    v-if="collapsed && reports.length >= 10"
    @click="expandNavbar"
    class="search__collapsed row center"
  >
    <i class="fa-solid fa-magnifying-glass"></i>
  </div>

  <Search
    v-else-if="!collapsed && reports.length >= 10"
    @update="filterReports($event)"
    @reset="resetSearch"
    class="search"
  />

  <Category
    v-for="report of filteredReports.length ? filteredReports : reports"
    :key="report._id"
    :href="`/report/${report._id}`"
    icon="fa-solid fa-chart-simple"
    :text="report.name"
    :collapsed="collapsed"
    @click="setReport(report)"
  />
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

import Search from '@components/input/Search.vue'
import Category from '@components/navigation/dashboard/components/Category.vue'
import IconBtn from '@components/button/IconBtn.vue'

export default {
  name: 'DashboardReports',
  components: { Search, Category, IconBtn },
  props: {
    collapsed: { type: Boolean, default: false },
  },
  setup(props) {
    const store = useStore()
    const filteredReports = ref([])
    const reports = computed(() => {
      const reports = store.getters.getReports

      return reports.filter(
        (r) =>
          (r.permission.length <= 0 ||
            r.permission.some((p) => p._id === store.state.user?.role?._id)) &&
          !r.isDashboard
      )
    })

    const setReport = (report) => {
      store.commit('setReport', report)
    }

    const filterReports = (filter) => {
      filteredReports.value = reports.value.filter((r) =>
        r.name.toLowerCase().includes(filter.toLowerCase())
      )
    }

    const resetSearch = () => {
      filteredReports.value = []
    }

    const expandNavbar = () => {
      if (props.collapsed) store.commit('expandDarboardNav', true)
    }

    return {
      filteredReports,
      reports,
      setReport,
      filterReports,
      resetSearch,
      expandNavbar,
    }
  },
}
</script>

<style lang="scss" scoped>
.search__collapsed {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 3px;
  cursor: pointer;
  background-color: var(--highlight-5);

  i {
    font-size: 1.5rem;
  }
}

.search {
  width: calc(100% - 30px);
  margin: 0 0 5px 0;
}
</style>
