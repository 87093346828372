<template>
  <div
    class="plan__container highlight"
    @click="$emit('selectPlan', plan.name)"
    :class="{
      active: selectedPlan === plan.name,
    }"
  >
    <div class="icon row center">
      <i :class="plan.icon"></i>
    </div>

    <div class="description column">
      <b>{{ t(upperCase(plan.name)) }}</b>

      <a :href="config.PRICING_URL" target="_blank" rel="noopener noreferrer">{{
        t('Learn more')
      }}</a>
    </div>

    <div v-if="selectedPlan === plan.name" class="billing__cycle row">
      <Select
        :values="['monthly', 'annual']"
        :selected="[billingPeriod]"
        :required="true"
        :allowNull="false"
        @update="$emit('selectPeriod', $event)"
      />
    </div>

    <div class="select row">
      <i class="fa-solid fa-angle-right"></i>
    </div>
  </div>
</template>

<script>
import config from '@config'
import Select from '@components/input/Select'

export default {
  name: 'Plan',
  emits: ['selectPlan'],
  components: { Select },
  props: {
    selectedPlan: { type: String, default: '' },
    billingPeriod: { type: String, default: '' },
    plan: {
      name: { type: String, default: '' },
      icon: { type: String, defualt: '' },
    },
  },
  setup() {
    return {
      config,
    }
  },
}
</script>

<style lang="scss" scoped>
.plan__container {
  width: calc(100% - 30px);
  padding: 15px;
  border-radius: 15px;
  margin-top: 5px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 65px calc(50% - 65px) calc(50% - 40px) 40px;
  grid-template-areas: 'icon description billing select';

  @media (orientation: portrait) {
    grid-template-rows: 2fr;
    row-gap: 10px;
    grid-template-columns: 65px calc(100% - 65px - 40px) 40px;
    grid-template-areas:
      'icon description select'
      'billing billing select';
  }

  .icon {
    grid-area: icon;
    width: 65px;
    height: 65px;
    border-radius: 15px;
    background-color: var(--gray);

    i {
      font-size: 1.5rem;

      &.fa-user {
        color: green !important;
      }
      &.fa-heart {
        color: purple !important;
      }
      &.fa-star {
        color: orange !important;
      }
      &.fa-building {
        color: blue !important;
      }
    }
  }

  .description {
    grid-area: description;
    width: calc(100% - 30px);
    padding: 0 15px;

    b {
      font-size: 1.5rem;
    }

    a {
      font-size: 0.85rem;
      text-decoration: underline !important;
      width: 100px;
    }
  }

  .billing__cycle {
    grid-area: billing;
    width: calc(100% - 30px);
    height: 100%;
    align-items: center;

    .select {
      margin: 0 !important;
    }
  }

  .select {
    grid-area: select;
    align-items: center;

    i {
      font-size: 3rem;
      color: var(--gray);
    }
  }
}
</style>
