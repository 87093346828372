<template>
  <div v-if="container" @click="stopPropag">
    <slot></slot>
  </div>

  <form v-else @submit.prevent @click="stopPropag"><slot></slot></form>
</template>

<script>
export default {
  name: 'Wrapper',
  props: {
    container: { type: Boolean, default: false },
  },
}
</script>
