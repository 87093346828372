<template>
  <div class="input__radio__button row">
    <div
      v-for="option in options"
      :key="option"
      class="option highlight row"
      @click="$emit('update', option)"
    >
      <input
        type="radio"
        :name="`radio-group-${uniqueId}`"
        :id="option"
        :value="option"
        :required="required"
        :checked="option === value"
      />

      <div class="checkbox row center">
        <div class="circle" v-if="option === value"></div>
      </div>
      <label :for="option" class="label">{{ t(upperCase(option)) }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Radio',
  props: {
    options: { type: Array, default: [] },
    value: { type: String, default: '' },
    required: { type: Boolean, default: false },
  },
  emits: ['update'],
  setup() {
    const uniqueId = crypto.randomUUID()

    return {
      uniqueId,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/media.scss';

.input__radio__button {
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;

  @media #{$mobile} {
    flex-direction: column;
  }

  input[type='radio'] {
    opacity: 0;
    position: absolute;
  }

  .option {
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 10px;
    border-radius: 15px;

    .checkbox {
      border: 1px solid var(--dark-10);
      background-color: white;
      border-radius: 50%;
      margin-right: 5px;
      width: 23px;
      height: 23px;
      align-items: center;
      justify-content: center;

      .circle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: var(--dark-10);
      }
    }
  }
}
</style>
