import store from '../../../store'

export default [
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('@modules/reports/Reports.vue'),
    meta: { authenticated: true, title: 'Sharedex | Reports' },
    children: [
      {
        path: 'add-report',
        name: 'Add Report',
        component: () => import('@modules/reports/views/config/index.vue'),
        beforeEnter: () => {
          if (store.state.reports.editReport && store.state.reports.editReport._id) {
            store.commit('setEditReport', {})
          }
        },
      },
      {
        path: 'edit-report/:id',
        name: 'Edit Report',
        component: () => import('@modules/reports/views/config/index.vue'),
        beforeEnter: async (to) => {
          if (!store.state.reports.editReport || !store.state.reports.editReport._id) {
            const report = await store.dispatch('getReport', to.params.id)
            store.commit('setEditReport', report)
          }
        },
      },
      {
        path: 'filter-lists',
        name: 'Filter Lists',
        component: () => import('@modules/reports/views/filterLists/index.vue'),
      },
    ],
  },
  {
    path: '/report/:id',
    name: 'Report',
    component: () => import('@modules/reports/views/report/index.vue'),
    meta: { authenticated: true, title: `Sharedex | Report` },
    beforeEnter: async (to) => {
      if (!store.state.reports.report || !store.state.reports.report._id) {
        const report = await store.dispatch('getReport', to.params.id)
        store.commit('setReport', report)

        if (!store.state.user || !store.state.user._id) {
          const user = await store.dispatch('getUser', store.state.user.id)
          store.commit('setUser', user)
        }

        if (
          report.permission.length > 0 &&
          !report.permission.some((p) => p._id === store.state.user?.role?._id)
        ) {
          return { name: 'Dashboard' }
        }

        return true
      }
    },
  },
]
