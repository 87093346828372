<template>
  <div :class="['input__select row', { small: small }]" @click="toggleOptions" ref="selectInput">
    <input v-if="required && !selected" required class="required" />

    <span v-if="displayValue" class="value nowrap">
      {{ displayValue }}
    </span>

    <i class="fa-solid fa-caret-down" :class="{ rotate: showOptions }"></i>
  </div>

  <div
    v-if="showOptions"
    class="input__select__dropdown scroll__y"
    ref="dropdownList"
    :style="{
      top: `${position.top}px`,
      left: `${position.left}px`,
      width: `${position.width}px`,
    }"
  >
    <div
      :class="['input__select__option row highlight', { small: small }]"
      v-if="allowNull && !multiSelect"
      @click="$emit('update', null)"
    ></div>

    <div
      :class="['input__select__option row highlight', { small: small }]"
      v-if="multiSelect"
      @click="selectAll"
    >
      <span>{{ t(selected.length === values.length ? 'Deselect all' : 'Select all') }}</span>
    </div>

    <div
      v-for="val in values"
      :key="val"
      :title="property ? val[property] : val"
      @click="$emit('update', val)"
      :class="[
        'input__select__option row highlight',
        {
          selected: selected.some((v) =>
            property !== '' ? val[property] === v[property] : v === val
          ),
          small: small,
        },
      ]"
    >
      <span v-if="property" :title="t(val[property])">
        {{ t(property === 'email' ? val[property] : upperCase(val[property])) }}
      </span>

      <span v-else :title="t(val)">{{ t(upperCase(val)) }}</span>
    </div>
  </div>
</template>

<script>
import { ref, computed, nextTick, reactive } from 'vue'
import { translate } from '@lib/translate'
import { upperCase } from '@lib/helper.js'

export default {
  name: 'Select',
  props: {
    values: { type: Array, default: [] },
    property: { type: String, default: '' },
    selected: { type: String, default: [] },
    required: { type: Boolean, default: false },
    allowNull: { type: Boolean, default: true },
    multiSelect: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    scrollContainers: { type: Array, default: ['app__container'] },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const showOptions = ref(false)
    const position = reactive({ top: 0, left: 0 })
    const selectInput = ref(null)
    const dropdownList = ref(null)

    const displayValue = computed(() => {
      if (!props.selected || props.selected.length === 0) return null

      let value
      if (!props.multiSelect) {
        value = props.selected[0]
        value = props.property && value[props.property] ? value[props.property] : value
      } else {
        value = props.selected.join(', ')
      }

      if (props.property !== 'email') value = upperCase(value)
      return translate(value)
    })

    const selectAll = () => {
      if (props.selected.length === props.values.length)
        return props.selected.forEach((s) => {
          emit('update', props.property ? s[property] : s)
        })

      props.values.forEach((v) => {
        if (!props.selected.some((s) => (props.property ? s[property] === v[property] : s === v)))
          emit('update', props.property ? v[property] : v)
      })
    }

    const setPosition = (select) => {
      position.width = select.right - select.left - 2
      position.top = select.top + (select.bottom - select.top) + 1
      position.left = select.left
    }

    const toggleOptions = async (event) => {
      event.stopPropagation()

      showOptions.value = !showOptions.value
      if (!showOptions.value) return

      await nextTick()
      setPosition(selectInput.value.getBoundingClientRect())

      // hide select if clicked  outside dropdown
      const options = document.querySelector('.input__select__dropdown')
      const clickable = [
        document.getElementById('app'),
        ...document.querySelectorAll('.main__form'),
      ]

      const removeClickHandler = () => {
        showOptions.value = false
        clickable.forEach((element) => {
          element.removeEventListener('click', clickHandler)
        })
      }

      const clickHandler = (e) => {
        if (options.contains(e.target)) {
          setPosition(selectInput.value.getBoundingClientRect())
          // close on click for non multi
          if (!props.multiSelect) removeClickHandler()
        } else {
          removeClickHandler()
        }
      }

      clickable.forEach((element) => {
        element.addEventListener('click', clickHandler)
      })

      // move select, if container is scrolled
      props.scrollContainers.forEach((container) => {
        const scrollContainer = document.querySelector(`.${container}`)
        if (!scrollContainer) return

        scrollContainer.addEventListener('scroll', (e) => {
          const select = selectInput?.value?.getBoundingClientRect() || 0
          if (select.top < 55 || !select) return (showOptions.value = false)
          setPosition(select)
        })
      })
    }

    return {
      showOptions,
      position,
      selectInput,
      dropdownList,
      displayValue,
      selectAll,
      toggleOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

.input__select {
  position: relative;
  cursor: pointer;
  background-color: var(--base);
  min-width: 100px;

  .value {
    margin: auto 0;
    max-width: 95%;
  }

  &.small {
    height: 29px;
    max-height: 29px;
  }

  i {
    position: absolute;
    right: 10px;
    top: 25%;
    color: var(--dark-4);
  }

  .required {
    display: absolute;
    z-index: -5;
  }
}

.input__select__dropdown {
  position: fixed;
  z-index: 10;
  width: 100%;
  max-height: calc(5 * 45px);
  background-color: var(--base);
  border-radius: 7px;
  border: 1px solid var(--dark-2);

  .input__select__option {
    width: calc(100% - 2px);
    padding: 0 10px;
    height: 43px;
    max-height: 43px;
    align-items: center;
    border-top: 1px solid var(--dark-2);

    &.small {
      height: 29px;
      max-height: 29px;
    }

    &:first-of-type {
      border-top: none;
    }

    &.selected {
      background-color: var(--highlight);
    }

    span {
      margin: auto 0;
    }
  }
}
</style>
