import store from '../store/index.js'
import { translate } from './translate'

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const monthsAbb = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export function stopPropag(event) {
  event.stopPropagation()
}

export function upperCase(value) {
  if (!value) return
  if (!isNaN(value) && value !== '') return value
  return String(value)[0].toUpperCase() + String(value).slice(1)
}

export function deepEquality(valA, valB) {
  return JSON.stringify(valA) === JSON.stringify(valB)
}

export function calculateTimePassed(start = new Date(), end = new Date(), timezoneOffset = 0) {
  start = new Date(start).getTime() + timezoneOffset
  end = new Date(end).getTime()

  const timePassed = { h: '00', m: '00', s: '00' }

  let timeDifference = end - start
  let seconds = Math.floor(timeDifference / 1000) % 60
  let minutes = Math.floor(timeDifference / (1000 * 60)) % 60
  let hours = Math.floor(timeDifference / (1000 * 60 * 60)) % 24

  timePassed.h = hours <= 9 ? `0${hours}` : hours
  timePassed.m = minutes <= 9 ? `0${minutes}` : minutes
  timePassed.s = seconds <= 9 ? `0${seconds}` : seconds

  return `${timePassed.h}:${timePassed.m}:${timePassed.s} h`
}

export function buildDateString(inputDate, abbMonth = false, abbDay = false) {
  const dateObj = new Date(inputDate)
  if (isNaN(dateObj.getTime())) return inputDate

  const year = dateObj.getFullYear()
  const currentYear = new Date().getFullYear()
  const month = dateObj.getMonth()
  const day = dateObj.getDate()
  const dayAbb = dateObj.toLocaleDateString('en-EN', { weekday: 'short' })
  const language = store.state.language || 'en'

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th'
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  const monthName = abbMonth ? monthsAbb[month] : months[month]

  let str =
    language === 'en'
      ? `${day}${getOrdinalSuffix(day)} ${monthName}`
      : `${day}. ${translate(monthName)}`

  if (abbDay) str = `${translate(dayAbb)}, ${str}`
  if (currentYear !== year) str += `, ${year}`

  return str
}

export function buildTimeString(time) {
  let str = ''
  try {
    str = new Date(time).toISOString().split('T')[1].split('.')[0]
  } catch (error) {
    let date = new Date().toISOString().split('T')[0]
    str = `${date}T${time}.000`
    str = str.split('T')[1].split('.')[0]
  } finally {
    return str
  }
}

export function copyToClipboard(value) {
  if (store.state.os !== 'web') return

  navigator.clipboard.writeText(value)

  store.commit('showNotification', {
    type: 'success',
    message: 'Copied to clipboard!',
  })
}

export function getDateOfMonth(
  date = 'first',
  month = new Date().getMonth(),
  year = new Date().getFullYear()
) {
  if (date === 'first') return new Date(year, month, 2).toISOString().split('T')[0]
  else if (date === 'last') return new Date(year, month + 1, 1).toISOString().split('T')[0]
  else if (date === 'firstLast')
    return {
      first: new Date(year, month, 2).toISOString().split('T')[0],
      last: new Date(year, month + 1, 1).toISOString().split('T')[0],
    }
}

export function getWeekFirstLastDate(date = new Date()) {
  const currentDate = new Date(date)
  const currentDay = currentDate.getDay()
  const diffToMonday = (currentDay + 5) % 7

  const firstDate = new Date(currentDate)
  firstDate.setDate(currentDate.getDate() - diffToMonday)
  firstDate.setHours(0, 0, 0, 0)

  const lastDate = new Date(firstDate)
  lastDate.setDate(firstDate.getDate() + 5)
  lastDate.setHours(23, 59, 59, 999)

  return {
    start: firstDate.toISOString().split('T')[0],
    end: lastDate.toISOString().split('T')[0],
  }
}

export function dateIsToday(date) {
  return new Date().toISOString().split('T')[0] === new Date(date).toISOString().split('T')[0]
}

export function getDatesInRange(startDate, endDate) {
  const dateArray = []
  let currentDate = new Date(startDate)

  while (currentDate <= new Date(endDate)) {
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    dateArray.push(`${year}-${month}-${day}`)
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dateArray
}

export function getWeekNumber(date) {
  date = new Date(date)
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))

  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))

  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7)

  return weekNo
}

export function detectType(value) {
  let type = 'unknown'
  if (typeof value === 'number' && !isNaN(value)) type = 'number'

  if (typeof value === 'string') {
    if (!isNaN(value)) type = 'number'

    const parsedDate = Date.parse(value)
    if (!isNaN(parsedDate)) type = 'date'

    type = 'string'
  }

  if (typeof value === 'boolean') type = 'boolean'
  if (value instanceof Date && !isNaN(value)) type = 'date'
  if (typeof value === 'number' && value % 1 !== 0) type = 'float'

  return type
}

export function formatNumber(value) {
  if (typeof value !== 'number') return value
  const locales = store.state.language === 'en' ? 'en-US' : 'de-DE'

  const formattedNumber = value.toLocaleString(locales, {
    minimumFractionDigits: value % 1 !== 0 ? 2 : 0,
    maximumFractionDigits: 2,
  })

  return formattedNumber
}
