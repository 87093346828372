<template>
  <div class="loading__spinner__container row">
    <div class="lds-ring row">
      <div class="light small" :class="{ dark: mode === 'dark', medium: size === 'medium' }"></div>
      <div class="light small" :class="{ dark: mode === 'dark', medium: size === 'medium' }"></div>
      <div class="light small" :class="{ dark: mode === 'dark', medium: size === 'medium' }"></div>
      <div class="light small" :class="{ dark: mode === 'dark', medium: size === 'medium' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: { default: 'light' },
    size: { default: 'small' },
  },
  name: 'Spinner',
}
</script>

<style lang="scss" scoped>
.loading__spinner__container {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.lds-ring {
  position: relative;
  width: 40px;
  height: 40px;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 8px;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

  &.light {
    border: 5px solid #fff;
    border-color: #fff transparent transparent transparent;
  }

  &.dark {
    border: 5px solid #0d0e21;
    border-color: #0d0e21 transparent transparent transparent;
  }

  &.small {
    width: 30px;
    height: 30px;
  }

  &.medium {
    width: 50px;
    height: 50px;
    border-width: 7px;
  }
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
