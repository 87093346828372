<template>
  <div :class="type" class="notification row box__shadow">
    <div class="line" :class="message.type"></div>

    <p>{{ t(message.text) }}</p>

    <i class="fa-solid fa-times" @click="hideNotification(message)"></i>
  </div>
</template>

<script>
import { useStore } from 'vuex'

export default {
  name: 'Message',
  props: {
    message: {
      type: Object,
      defualt: {
        type: '',
        text: '',
        created: '',
      },
    },
  },
  setup() {
    const store = useStore()

    const hideNotification = (notification) => {
      store.commit('hideNotification', notification)
    }

    return {
      hideNotification,
    }
  },
}
</script>

<style lang="scss" scoped>
.notification {
  margin: 0 10px 10px 0;
  width: 370px;
  max-width: calc(95% - 30px);
  padding: 10px 15px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  text-align: center;
  position: relative;
  background-color: var(--base);

  @media (orientation: portrait) {
    margin: 0 0 5px 0;
  }

  p {
    text-align: start;
    width: auto;
    margin: 0;
    width: 100%;
    padding: 0 10px 0 0;
    font-size: 0.9rem;
  }

  .line {
    width: 6px;
    height: 60%;
    position: absolute;
    left: 0;
    border-radius: 7px;

    &.success {
      background-color: var(--valid);
    }

    &.error {
      background-color: var(--invalid);
    }

    &.info {
      background-color: var(--dark-10);
    }
  }

  .fa-times {
    font-size: 1.2rem;
    cursor: pointer;
  }
}
</style>
