<template>
  <div v-if="subscription.plan === 'trial'" class="subscription__info__container column">
    <div class="info row">
      <b>{{ t('Current plan') }}</b>

      <span>
        {{ upperCase(subscription.plan) }}
        <i class="fa-solid fa-user"></i>
        {{ subscription.users }} / 5
      </span>
    </div>

    <div class="info row">
      <b>{{ t('Trial expiration date') }}</b>
      <span>{{ buildDateString(subscription.trialEnd) }}</span>
    </div>
  </div>

  <div v-else class="subscription__info__container column">
    <div class="info row">
      <b>{{ t('Current plan') }}</b>

      <span>
        {{ upperCase(subscription.plan) }}
        <i class="fa-solid fa-user"></i>
        <span>{{ subscription.users }}</span>
        <span v-if="subscription.plan === 'startup'">/ 5</span>
        <span v-else-if="subscription.plan === 'basic'"> / 20</span>
        <span v-else-if="subscription.plan === 'premium'"> / 50</span>
        <span v-else-if="subscription.plan === 'corporate'"> / 100</span>
      </span>
    </div>

    <div class="info row">
      <b>{{ t('Subscription started') }}</b>
      <span>{{ buildDateString(subscription.created) }}</span>
    </div>

    <div class="info row">
      <b>{{ t('Billing circle started') }}</b>
      <span>{{ buildDateString(subscription.periodStart) }}</span>
    </div>

    <div class="info row">
      <b>{{ t('Billing circle ends') }}</b>
      <span>{{ buildDateString(subscription.periodEnd) }}</span>
    </div>

    <div class="info row">
      <b>{{ t('Subscription interval') }}</b>
      <span>{{ upperCase(subscription.interval) }}</span>
    </div>

    <div class="info row">
      <b>{{ t('Billing amount') }}</b>

      <span>
        {{
          (subscription.amount / 100).toLocaleString('en-US', {
            minimumFractionDigits: 2,
          })
        }}
        {{ subscription.currency === 'eur' ? '€' : '$' }}
      </span>
    </div>

    <div class="info row" v-if="subscription.canceled">
      <b>{{ t('Canceled') }}</b>

      <span> {{ t('Access till') }} {{ buildDateString(subscription.periodEnd) }} </span>
    </div>
  </div>
</template>

<script>
import { buildDateString } from '@lib/helper'

export default {
  name: 'InfoTable',
  props: {
    subscription: { type: Object, default: {} },
  },
  setup() {
    return {
      buildDateString,
    }
  },
}
</script>

<style lang="scss" scoped>
.subscription__info__container {
  margin-bottom: 15px;
  width: 100%;

  .info {
    margin: 0 0 7px 0;
    padding: 3px;
    font-size: 1.1rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--dark-2);

    i {
      margin: 0 7px 5px 7px;
    }
  }
}
</style>
