<template>
  <div class="form__btn__area row">
    <IconBtn
      v-if="deleteIcon"
      icon="fa fa-trash"
      :classes="['invalid', 'small']"
      class="delete-btn"
      @click="$emit('delete')"
    />

    <Button
      text="Cancel"
      :classes="['light', mobile ? 'small' : '']"
      @click="$emit('cancel')"
      class="button"
    />

    <Submit
      :id="id"
      :text="deleteBtn ? 'Delete' : 'Save'"
      :classes="[deleteBtn ? 'invalid' : '', mobile ? 'small' : '']"
      class="button"
    />
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Button from '@components/button/Button.vue'
import Submit from '@components/button/Submit.vue'
import IconBtn from '@components/button/IconBtn.vue'

export default {
  name: 'ButtonArea',
  components: { Button, Submit, IconBtn },
  emits: ['cancel', 'submit', 'delete'],
  props: {
    deleteBtn: { type: Boolean, default: false },
    deleteIcon: { type: Boolean, default: false },
    id: { type: String, default: '' },
  },
  setup() {
    const store = useStore()
    const mobile = computed(() => store.state.screen.device === 'mobile')

    return {
      mobile,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/media.scss';

.form__btn__area {
  margin-top: 10px;
  justify-content: flex-end;
  align-items: center;

  @media #{$mobile} {
    justify-content: space-between;
  }

  .button {
    margin: 0 0 0 5px;
  }

  .delete-btn {
    margin-right: auto;
  }
}
</style>
