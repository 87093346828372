<template>
  <div class="icon row">
    <div class="icon-wrapper row">
      <i :class="icon"></i>
    </div>

    <Input
      type="text"
      :value="value"
      :placeholder="placeholder"
      :info="info"
      @update="$emit('update', $event)"
    />
  </div>
</template>

<script>
import Input from '@components/input/Input'

export default {
  name: 'IconInput',
  components: { Input },
  props: {
    icon: { type: String, default: '' },
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    info: { type: String, default: '' },
  },
}
</script>

<style lang="scss" scoped>
.icon {
  align-items: center;

  .icon-wrapper {
    width: 50px;
    height: 43px;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-10);
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border: 1px solid transparent;
    border-right: none;

    i {
      font-size: 1.5rem;
      color: white;
    }
  }

  :deep(input) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
</style>
