<template>
  <div class="input__label nowrap">
    <span>{{ t(text) }}</span>
    <span class="small__label">{{ t(small) }}</span>
    <span v-if="required" class="required">*</span>
  </div>
</template>

<script>
export default {
  name: 'Label',
  props: {
    text: { type: String, default: '' },
    small: { type: String, default: '' },
    required: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
.input__label {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 0 3px 5px;
  transition: all 0.3s;
  height: 20px;
  padding: 0 2px;
  color: var(--font);

  .small__label {
    font-size: 0.8rem;
    margin-left: 3px;
  }

  .required {
    margin-left: -3px;
  }
}
</style>
