<template>
  <div v-if="Object.keys(input).length <= 0"></div>

  <div v-else class="form__input__group column" :style="{ width: `${input.width}%` }">
    <Label
      v-if="input.type !== 'toggle' && input.label"
      :text="input.label"
      :required="input.required"
    />

    <Toggle
      v-if="input.type === 'toggle'"
      :label="input.label"
      :active="input.value"
      @update="$emit('update', { key: input.key, value: $event })"
    />

    <Select
      v-else-if="input.type === 'select'"
      :values="input.values"
      :property="input.property"
      :selected="input.selected"
      :required="input.required"
      :allowNull="input.allowNull"
      :multiSelect="input.multiSelect"
      :scrollContainers="input.scrollContainers"
      @update="$emit('update', { key: input.key, value: $event })"
    />

    <Static v-else-if="input.type === 'static'" :value="input.value" />

    <IconInput
      v-else-if="input.type === 'icon'"
      :value="input.value"
      :placeholder="input.placeholder"
      :icon="input.icon"
      :info="input.info"
      @update="$emit('update', { key: input.key, value: $event })"
    />

    <SelectList
      v-else-if="input.type === 'selectList'"
      :values="input.values"
      :property="input.property"
      :selected="input.selected"
      :max="input.max"
      :allowNull="allowNull"
      @update="$emit('update', { key: input.key, value: $event })"
    />

    <Password
      v-else-if="input.type === 'password'"
      :value="input.value"
      :required="input.required"
      :placeholder="input.placeholder"
      :info="input.info"
      @update="$emit('update', { key: input.key, value: $event })"
    />

    <Radio
      v-else-if="input.type === 'radio'"
      :value="input.value"
      :required="input.required"
      :options="input.options"
      @update="$emit('update', { key: input.key, value: $event })"
    />

    <div v-else-if="input.type === 'placeholder'" class="placeholder__input"></div>

    <Input
      v-else
      :type="input.type"
      :value="input.value"
      :required="input.required"
      :placeholder="input.placeholder"
      :step="input.step"
      :info="input.info"
      @update="$emit('update', { key: input.key, value: $event })"
    />
  </div>
</template>

<script>
import Label from '@components/input/Label.vue'
import Input from '@components/input/Input.vue'
import Select from '@components/input/Select.vue'
import Toggle from '@components/input/Toggle.vue'
import Static from '@components/input/Static.vue'
import IconInput from '@components/input/IconInput.vue'
import SelectList from '@components/input/selectList/SelectList.vue'
import Password from '@components/input/Password.vue'
import Radio from '@components/input/Radio.vue'

export default {
  name: 'FormInput',
  props: {
    input: {
      type: Object,
      default: {
        label: '',
        type: 'text',
        key: '',
        property: '',
        required: false,
        placeholder: '',
        icon: '',
        width: '100',
        step: 1,
        values: [],
        selected: [],
        allowNull: true,
        info: '',
        multiSelect: false,
        scrollContainers: [],
      },
    },
  },
  components: {
    Label,
    Input,
    Select,
    Toggle,
    Static,
    IconInput,
    SelectList,
    Password,
    Radio,
  },
  emits: ['update'],
}
</script>

<style lang="scss" scoped>
@import '@assets/media.scss';

.form__input__group {
  width: calc(50% - 5px);
  justify-content: center;
  margin-bottom: 10px;

  .placeholder__input {
    margin: 10px 0;
  }
}
</style>
