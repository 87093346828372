import { translate } from './translate'
import { stopPropag, upperCase, deepEquality } from './helper.js'
import { hasPermission, hasPlan } from '@modules/authentication/helper'

export default {
  install: (app) => {
    app.config.globalProperties.t = translate
    app.config.globalProperties.upperCase = upperCase
    app.config.globalProperties.deepEquality = deepEquality
    app.config.globalProperties.stopPropag = stopPropag
    app.config.globalProperties.hasPermission = hasPermission
    app.config.globalProperties.hasPlan = hasPlan
  },
}
