<template>
  <div class="input__toggle row">
    <p>{{ t(label) }}</p>

    <div :class="['outter row', { active: active }, `${size}`]" @click="$emit('update', !active)">
      <div class="toggle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    label: { type: String, default: '' },
    active: { type: Boolean, default: false },
    size: { type: String, default: 'medium' },
  },
  emits: ['update'],
}
</script>

<style lang="scss" scoped>
.input__toggle {
  width: 100%;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }

  .outter {
    width: 50px;
    height: 25px;
    border-radius: 25px;
    align-items: center;
    cursor: pointer;
    background-color: var(--dark-2);

    .toggle {
      width: 19px;
      height: 19px;
      margin-left: 3px;
      border-radius: 50%;
      background: var(--dark-10);
      transition: all 0.2s ease-in-out;
    }

    &.active {
      background-color: var(--highlight);

      .toggle {
        margin-left: 27px;
        transition: all 0.2s ease-in-out;
      }
    }

    &.small {
      height: 20px;
      width: 40px;

      .toggle {
        width: 14px;
        height: 14px;
      }

      &.active {
        .toggle {
          margin-left: 22px;
        }
      }
    }
  }
}
</style>
