import config from '../config.js'

export const getProductLinks = (email = '', id = '', env = 'development') => {
  let query = `prefilled_email=${email}&client_reference_id=${id}`
  const links = config.STRIPE_LINKS

  for (let link in links[env]) {
    links[env][link].monthly += query
    links[env][link].annual += query
  }

  return links[env]
}
