<template>
  <div class="select__list__controlls scroll__x row">
    <span @click="$emit('showSearch')" class="nowrap row center" :title="t('Search')">
      <i v-if="showSearch" class="fa-solid fa-xmark"></i>
      {{ t('Search') }}
      ({{ searchResults }})
    </span>

    <span
      v-if="valueCount > 5"
      @click="$emit('showAll')"
      class="nowrap"
      :title="t(!showAll ? 'Show more' : 'Show less')"
    >
      <i v-if="showAll" class="fa-solid fa-xmark"></i>
      {{ t(!showAll ? 'Show more' : 'Show less') }}
    </span>

    <span
      v-if="max > 1"
      @click="$emit('quickSelect')"
      class="nowrap"
      :title="t(quickSelect === 'all' ? 'Select all' : 'Deselect all')"
    >
      {{ t(quickSelect === 'all' ? 'Select all' : 'Deselect all') }}
      ({{ selectedValues }})
    </span>
  </div>
</template>

<script>
export default {
  name: 'Controlls',
  props: {
    showAll: { type: Boolean, default: false },
    showSearch: { type: Boolean, default: false },
    quickSelect: { type: String, default: 'all' },
    max: { type: Number, default: 100 },
    valueCount: { type: Number, default: 0 },
    searchResults: { type: Number, default: 0 },
    selectedValues: { type: Number, default: 0 },
  },
  emits: ['showSearch', 'showAll', 'quickSelect'],
  setup() {},
}
</script>

<style lang="scss" scoped>
.select__list__controlls {
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.85rem;
  font-size: 0.85rem;
  margin-bottom: 5px;

  .fa-xmark {
    margin-right: 5px;
  }

  span {
    cursor: pointer;
    padding: 3px 12px;
    border-radius: 15px;
    background: var(--dark-2);
    margin: 0 7px 3px 0;

    &:hover {
      background: var(--highlight);
    }

    &:active {
      transform: scale(0.98);
    }
  }
}
</style>
