<template>
  <div class="list__container">
    <h3>{{ t(headline) }}</h3>

    <div class="list__body scroll__x">
      <ListHeader :columns="columns" />

      <ListEntry
        v-for="entry in entries"
        :key="entry"
        :entry="entry"
        :columns="columns"
        :options="options"
        :scrollContainer="scrollContainer"
        @optionClick="$emit('optionClick', { key: $event, value: entry })"
      />
    </div>
  </div>
</template>

<script>
import ListHeader from './components/ListHeader'
import ListEntry from './components/ListEntry'

export default {
  name: 'List',
  components: { ListHeader, ListEntry },
  emits: ['optionClick'],
  props: {
    headline: { type: String, default: '' },
    entries: { type: Array, default: [] },
    columns: { type: Array, defualt: [] },
    options: { type: Array, default: [] },
    scrollContainer: { type: String, default: '' },
  },
}
</script>

<style lang="scss" scoped>
.list__container {
  width: 100%;

  h3 {
    margin: 10px 0 15px 0;
  }

  .list__body {
    width: 100%;
  }
}
</style>
