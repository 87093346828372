<template>
  <div v-if="invoices.length > 0" class="invoice__list__container">
    <List
      headline="Past invoices"
      :columns="['date', 'plan', 'amount', 'status']"
      :entries="
        invoices.map((invoice) => ({
          id: invoice.id,
          date: buildDateString(invoice.start),
          plan: upperCase(invoice.plan),
          amount: `${(invoice.total / 100).toLocaleString('en-US', {
            minimumFractionDigits: 2,
          })} ${invoice.currency === 'eur' ? '€' : '$'}`,
          status: upperCase(invoice.status),
        }))
      "
      :options="['download', 'open']"
      @optionClick="handleInvoiceOption($event)"
    />
  </div>
</template>

<script>
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { buildDateString } from '@lib/helper'

import List from '@components/list/List.vue'

export default {
  name: 'InvoiceList',
  components: { List },
  setup() {
    const store = useStore()

    const invoices = computed(() => {
      return store.getters.getInvoices
    })

    const handleInvoiceOption = (event) => {
      const { key, value } = event
      let invoice = invoices.value.find((invoice) => invoice.id === value.id)
      window.open(key === 'open' ? invoice.url : invoice.pdf, '_blank')
    }

    onBeforeMount(async () => {
      await store.dispatch('getInvoices')
    })

    return {
      invoices,
      handleInvoiceOption,
      buildDateString,
    }
  },
}
</script>

<style lang="scss" scoped>
.invoice__list__container {
  width: calc(100% - 40px);
  max-width: 700px;
  padding: 0 20px;
  margin: 0 auto;
}
</style>
