export default {
  state() {
    return {
      reports: [],
      editReport: {},
      report: {},
      filterValues: {},
      filterLists: [],
      componentSettingsId: '',
    }
  },

  mutations: {
    setReports(state, reports) {
      state.reports = [...reports]
    },

    setEditReport(state, report) {
      state.editReport = { ...report }
    },

    setReport(state, report) {
      state.report = { ...report }
      state.filterValues = {}
    },

    setComponentSettingsId(state, id) {
      state.componentSettingsId = id
    },

    setFilterValue(state, { key, value, multiSelect }) {
      if (!multiSelect) return (state.filterValues[key] = value)

      if (!state.filterValues[key]) state.filterValues[key] = [value]
      else if (state.filterValues[key].some((v) => v === value))
        state.filterValues[key] = state.filterValues[key].filter((v) => v !== value)
      else state.filterValues[key].push(value)
    },

    updateReportfilter(state, filter) {
      state.report.filter = { ...filter }
    },

    setFilterLists(state, lists) {
      state.filterLists = [...lists]
    },
  },

  actions: {
    /*
      ROUTE /:organizationId/reports
    */
    async getReports({ dispatch, commit, rootState }) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/reports`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.reports) {
        commit('setReports', data.reports)
      }
    },

    /*
      ROUTE /:organizationId/report/:reportId?
    */
    async getReport({ dispatch, commit, rootState }, reportId) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/report/${reportId}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.report) return data.report

      return null
    },

    async addReport({ dispatch, commit, rootState }, report) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/report`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ report }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    async updateReport({ dispatch, commit, rootState }, report) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/report`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ report }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
      }
    },

    async deleteReport({ dispatch, commit, rootState }, reportId) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/report/${reportId}`
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return null
    },

    /*
      ROUTE /:organizationId/report/config/:configId
    */
    async getComponentConfig({ dispatch, commit, rootState }, configId) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/report/config/${configId}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.config) return data.config

      return null
    },

    async updateComponentConfig({ dispatch, commit, rootState }, config) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/report/config/${config._id}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ config }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })
    },

    /*
      ROUTE /:organizationId/report/filter/:reportId
    */
    async updateReportFilter({ state, dispatch, commit, rootState }, filter) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/report/filter/${state.report._id}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ filter }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.report) {
        commit('updateReportfilter', data.report.filter)
        return true
      }

      return false
    },

    /*
      ROUTE /:organizationId/report/filter-lists/
    */
    async getFilterLists({ dispatch, commit, rootState }) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/report/filter-lists`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.filterLists) commit('setFilterLists', data.filterLists)
    },

    /*
      ROUTE /:organizationId/report/filter-list/:listId?
    */
    async addFilterList({ dispatch, commit, rootState }, filterList) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/report/filter-list`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ filterList }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    async updateFilterList({ dispatch, commit, rootState }, filterList) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/report/filter-list/${filterList.id}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ filterList }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    async deleteFilterList({ dispatch, commit, rootState }, id) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/report/filter-list/${id}`
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },
  },

  getters: {
    getReports(state) {
      return state.reports
    },

    getEditReport(state) {
      return state.editReport
    },

    getReport(state) {
      return state.report
    },

    getDashboardReport(state) {
      return state.reports.filter((r) => r.isDashboard)[0]
    },

    getComponentSettingsId(state) {
      return state.componentSettingsId
    },

    getFilterLists(state) {
      return state.filterLists
    },

    getReportFilters(state) {
      return state?.report?.filter
    },

    getFilterValues(state) {
      return state.filterValues
    },
  },
}
