<template>
  <Notification />
  <SubscriptionWarning />
  <Onboarding />

  <div :class="['app__container', { auth: userId, maxWidth: maxWidth }]">
    <MainNavbar class="main__navbar" />
    <DashboardNavbar v-if="userId" class="dashboard__navbar" />
    <Router class="router" />
  </div>
</template>

<script>
import { onBeforeMount, watch, computed } from 'vue'
import { useStore } from 'vuex'

import Notification from '@components/notification/Notification.vue'
import SubscriptionWarning from '@components/warning/Subscription.vue'
import MainNavbar from '@components/navigation/main/Main.vue'
import Router from '@components/router/Router.vue'
import DashboardNavbar from '@components/navigation/dashboard/Dashboard.vue'
import Onboarding from '@modules/onboarding/index.vue'

export default {
  name: 'App',
  components: {
    Notification,
    SubscriptionWarning,
    MainNavbar,
    Router,
    DashboardNavbar,
    Onboarding,
  },
  setup() {
    const store = useStore()
    const userId = computed(() => store.state.user.id)
    const maxWidth = computed(() => !store.state.dashboardNav.expanded)
    const autoLoginOngoing = computed(() => store.state.authentication.autoLoginOngoing)

    watch([userId, autoLoginOngoing], async ([id, isAutoLoginOngoing]) => {
      if (id && !isAutoLoginOngoing) await store.dispatch('init')
    })

    onBeforeMount(async () => {
      await store.dispatch('autoLogin')
      store.dispatch('initScreenListener')
    })

    return {
      userId,
      maxWidth,
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
@import '@assets/variables.scss';
@import '@assets/media.scss';
@import '@assets/classes.scss';
@import '@assets/transition.scss';
@import '~@fortawesome/fontawesome-free/css/all.css';

body {
  margin: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

#app {
  color: var(--font);
  background-color: var(--base);
  width: 100%;
  height: 100%;

  .app__container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 55px calc(100% - 55px);
    grid-template-columns: 1fr;
    grid-template-areas:
      'main__navbar'
      'router';

    &.auth {
      transition: all 0.2s linear;
      grid-template-columns: 300px calc(100% - 300px);
      grid-template-areas:
        'dashboard__navbar main__navbar'
        'dashboard__navbar router';

      &.maxWidth {
        transition: all 0.2s linear;
        grid-template-columns: 75px calc(100% - 75px);
      }

      @media (max-width: 1110px) {
        grid-template-rows: 55px calc(100% - 55px);
        grid-template-columns: 20% 80% !important;
        grid-template-areas:
          'dashboard__navbar main__navbar'
          'router router';
      }

      .main__navbar {
        grid-area: main__navbar;
      }

      .dashboard__navbar {
        grid-area: dashboard__navbar;
      }

      .router {
        grid-area: router;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        padding: 15px;

        @media #{$mobile} {
          width: calc(100% - 10px);
          height: calc(100% - 10px);
          padding: 5px;
        }
      }

      .mobile-navbar {
        grid-area: mobile-navbar;
      }
    }
  }
}
</style>
