<template>
  <div class="icon__button row" :class="[...classes]">
    <Spinner v-if="loadingBtnId !== '' && loadingBtnId === id" />

    <i v-else :class="icon"></i>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Spinner from '@components/loading/Spinner'

export default {
  name: 'IconBtn',
  components: { Spinner },
  props: {
    icon: { type: String, default: '' },
    id: { type: String, default: '' },
    classes: { type: Array, default: [] },
  },
  setup() {
    const store = useStore()

    const loadingBtnId = computed(() => {
      return store.state.loading.id
    })

    return {
      loadingBtnId,
    }
  },
}
</script>

<style lang="scss" scoped>
.icon__button {
  width: 45px;
  min-width: 45px;
  height: 45px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  font-size: 1.1rem;

  &.small {
    width: 35px;
    min-width: 35px;
    height: 35px;
    font-size: 1rem;
  }

  &.extra__small {
    width: 25px;
    min-width: 25px;
    height: 25px;
    font-size: 0.9rem;
  }

  &.forbidden {
    cursor: not-allowed;
  }

  &.round {
    border-radius: 50%;
  }

  &.dark {
    background: var(--dark-10);
    color: white;
  }

  &.light {
    background: var(--highlight-5);
    color: var(--font);
  }

  &.active {
    background: var(--highlight);
  }

  &.invalid {
    background-color: var(--invalid);
    color: white;
  }

  &.valid {
    color: rgb(124, 209, 124);
  }

  &.transparent {
    background: transparent;
    width: 44px;
    height: 44px;
    box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem, rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;

    &.dark {
      border: 1px solid var(--dark-10);
      box-shadow: none;
    }

    &.light {
      border: 1px solid var(--dark-2);
      box-shadow: none;
    }
  }

  &:hover {
    background: var(--highlight-5);
    color: var(--font);
  }
}
</style>
