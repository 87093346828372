<template>
  <div class="options__container row">
    <i @click="toggleOptions($event)" ref="optionIcon" class="fa-solid fa-ellipsis-vertical"></i>

    <OptionList
      v-if="showOptions"
      :options="options"
      v-motion
      :initial="{ scale: 0.9 }"
      :enter="{ scale: 1 }"
      :leave="{ scale: 0.9, delay: 100 }"
      @optionClick="emitOption($event)"
      :style="{ top: `${position.top}px`, right: `${position.right}px` }"
      ref="optionList"
    />
  </div>
</template>

<script>
import { ref, reactive, nextTick } from 'vue'

import OptionList from './components/OptionList.vue'

export default {
  name: 'Options',
  props: {
    options: { type: Array, default: [] },
    scrollContainer: { type: String, default: 'app__container' },
  },
  emits: ['optionClick'],
  components: { OptionList },
  setup(props, { emit }) {
    const showOptions = ref(false)
    const position = reactive({ top: 0, right: 0 })
    const optionList = ref(null)
    const optionIcon = ref(null)

    const toggleOptions = async (event) => {
      event.stopPropagation()

      showOptions.value = !showOptions.value
      if (!showOptions.value) return

      await nextTick()

      const icon = optionIcon.value.getBoundingClientRect()
      const listWidth = optionList.value.$el.offsetWidth

      position.top = icon.top
      position.right = window.innerWidth - icon.right - 5

      if (listWidth > icon.left) position.right -= listWidth - icon.left

      const options = document.querySelector('.options__list')
      const clickable = [
        document.getElementById('app'),
        ...document.querySelectorAll('.main__form'),
      ]

      clickable.forEach((element) => {
        element.addEventListener('click', (e) => {
          if (!options.contains(e.target)) showOptions.value = false
        })
      })

      const scrollContainer = document.querySelector(`.${props.scrollContainer}`)
      scrollContainer.addEventListener('scroll', (e) => {
        position.top = optionIcon.value.getBoundingClientRect().top
        position.right = optionIcon.value.getBoundingClientRect().right
      })
    }

    const emitOption = (option) => {
      emit('optionClick', option)
      showOptions.value = false
    }

    return {
      showOptions,
      position,
      optionList,
      optionIcon,
      toggleOptions,
      emitOption,
    }
  },
}
</script>

<style lang="scss" scoped>
.options__container {
  align-items: center;
  position: relative;

  i {
    cursor: pointer;
    font-size: 1.25rem;
  }
}
</style>
