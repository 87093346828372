import store from '../../store/index.js'
import components from './translations/components.js'
import notifications from './translations/notifications.js'

const translation = {
  en: {
    ...components.en,
    ...notifications.en,
  },
  de: {
    ...components.de,
    ...notifications.de,
  },
}

export const translate = (string) => {
  const language = store.state.language ? store.state.language : 'en'
  let str = translation[language][string]

  if (str) return str

  return string
}

export default (obj) => {
  translation.en = {
    ...translation.en,
    ...obj.en,
  }

  translation.de = {
    ...translation.de,
    ...obj.de,
  }
}
