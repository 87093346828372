export default {
  en: {
    Delete: 'Delete',
    Cancel: 'Cancel',
    Add: 'Add',
    Export: 'Export',
    Edit: 'Edit',
    Delete: 'Delete',
    Search: 'Search',
    'Show more': 'Show more',
    'Show less': 'Show less',
    'rotation-warning': 'Please rotate your device to portrait mode to ensure full functionality',
    'Select all': 'Select all',
    'Deselect all': 'Deselect all',
    'Your trial has expired': 'Your trial has expired',
    'If you need assistance, please': 'If you need assistance, please',
    'contact us': 'contact us',
    'Please contact your organization administrator.':
      'Please contact your organization administrator.',
    'Filter columns': 'Filter columns',
    Sum: 'Sum',
    rows: 'rows',
    columns: 'columns',
    total: 'total',
    percentage: 'percentage',
    'Grand Total': 'Grand Total',
    'Total Percentage': 'Total Percentage',
    Settings: 'Settings',
    Profile: 'Profile',
    'Create Report': 'Create Report',
  },

  de: {
    Delete: 'Löschen',
    Cancel: 'Abbrechen',
    Add: 'Hinzufügen',
    Export: 'Exportieren',
    Edit: 'Bearbeiten',
    Delete: 'Löschen',
    Search: 'Suchen',
    'Show more': 'Mehr anzeigen',
    'Show less': 'Weniger anzeigen',
    'rotation-warning':
      'Bitte drehe dein Gerät in den Hochformatmodus, um die volle Funktionalität sicherzustellen',
    'Select all': 'Alles ausw.',
    'Deselect all': 'Alles abw.',
    'Your trial has expired': 'Deine Testversion ist abgelaufen',
    'If you need assistance, please': 'Wenn du Unterstützung benötigst, bitte',
    'contact us': 'kontaktiere uns',
    'Please contact your organization administrator.':
      'Bitte wende dich an deinen Organisationsadministrator.',
    'Filter columns': 'Spaltenfilter',
    Sum: 'Summe',
    rows: 'Zeilen',
    columns: 'Spalten',
    total: 'gesamt',
    percentage: 'prozentual',
    'Grand Total': 'Gesamtsumme',
    'Total Percentage': 'Gesamtprozentsatz',
    Settings: 'Einstellungen',
    Profile: 'Profil',
    'Create Report': 'Report erstellen',
  },
}
