<template>
  <div class="info__button__container">
    <i
      class="fa-solid fa-circle-info"
      ref="infoBtn"
      @mouseenter="showInfoDiv($event, true)"
      @mouseleave="showInfoDiv($event, false)"
      @click="showInfoDiv($event, true)"
    >
    </i>

    <div
      class="info box__shadow"
      :class="{ show: showInfo }"
      :style="{
        top: `${position.top}px`,
        left: `${position.left}px`,
      }"
    >
      {{ t(info) }}
    </div>

    <div
      class="info__button__overlay"
      v-if="orientation === 'portrait' && showInfo"
      @click="showInfo = false"
    ></div>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'BaseInfo',
  props: {
    info: { type: String, default: '' },
  },
  setup(props) {
    const store = useStore()
    const infoBtn = ref(null)
    const showInfo = ref(false)
    const infoText = ref(props.info.split('\n').filter((el) => el !== ''))
    const position = reactive({ top: 0, left: 0 })
    const orientation = computed(() => store.state.screen.orientation)

    const showInfoDiv = (_, show) => {
      const icon = infoBtn.value.getBoundingClientRect()
      const marginLeft = icon.left < 150 ? 145 : 0
      const marginRight = window.innerWidth - icon.right < 150 ? -145 : 0

      position.top = icon.top + 20
      position.left = icon.left - 145 + 8 + marginLeft + marginRight

      showInfo.value = show
    }

    return {
      infoBtn,
      showInfo,
      position,
      infoText,
      orientation,
      showInfoDiv,
    }
  },
}
</script>

<style lang="scss" scoped>
.info__button__container {
  .info {
    display: none;
    position: fixed;
    width: 270px;
    background-color: var(--base);
    padding: 10px;
    border-radius: 15px;
    color: valr(--font);
    z-index: 5;

    &.show {
      display: block;
    }
  }

  .info__button__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
  }
}
</style>
