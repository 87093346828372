import store from './store/index.js'

export default {
  URL: 'https://sharedex-a12fc96d12a8.herokuapp.com',
  BACKEND_PORT: 3000,
  PRICING_URL: 'https://sharedex.co/pricing',
  get TERMS_URL() {
    return `https://sharedex.co/${store.state.language}/terms`
  },
  get PRIVACY_URL() {
    return `https://sharedex.co/${store.state.language}/privacy`
  },
  STRIPE_FALLBACK_DEV: 'https://billing.stripe.com/p/login/test_00g152eAm9zadR6eUU',
  STRIPE_FALLBACK_LIVE: 'https://billing.stripe.com/p/login/8wM17BcmNd7o7KM6oo',
  STRIPE_PLANS: [
    { name: 'individual', icon: 'fa-solid fa-user' },
    { name: 'basic', icon: 'fa-solid fa-rocket' },
    { name: 'premium', icon: 'fa-solid fa-star' },
    // { name: 'corporate', icon: 'fa-solid fa-building' },
  ],
  STRIPE_LINKS: {
    development: {
      individual: {
        monthly: 'https://buy.stripe.com/test_fZe8zp7rB59V5MIdQS?',
        annual: 'https://buy.stripe.com/test_dR68zp3bl45R2AwfZ1?',
      },
      basic: {
        monthly: 'https://buy.stripe.com/test_8wM2b14fp45Rdfa8wA?',
        annual: 'https://buy.stripe.com/test_00gg1R9zJ8m74IE5kp?',
      },
      premium: {
        monthly: 'https://buy.stripe.com/test_cN25nd3bl6dZ2Aw14a?',
        annual: 'https://buy.stripe.com/test_3cseXN8vF0TFgrmeV1?',
      },
      corporate: {
        monthly: 'https://buy.stripe.com/test_6oEaHx4fp0TF8YU3ck?',
        annual: 'https://buy.stripe.com/test_eVa2b1aDN45R2AwcMV?',
      },
    },
    live: {
      individual: {
        monthly: 'https://buy.stripe.com/3cs9Eu7Oe5tD1LW3cc?',
        annual: 'https://buy.stripe.com/28o7wm0lMcW5bmwdQR?',
      },
      basic: {
        monthly: 'https://buy.stripe.com/14keYOd8y1dn76gdQT?',
        annual: 'https://buy.stripe.com/eVa2c21pQ4pz2Q0dQU?',
      },
      premium: {
        monthly: 'https://buy.stripe.com/dR66sid8y7BL76g28d?',
        annual: 'https://buy.stripe.com/9AQcQG0lM7BLcqA9AG?',
      },
      corporate: {
        monthly: 'https://buy.stripe.com/3cs3g63xYe09cqA8wD?',
        annual: 'https://buy.stripe.com/bIY3g63xY09j1LWcMU?',
      },
    },
  },
}
