export default {
  state() {
    return {
      general: {},
      organization: {},
      teams: [],
      roles: [],
      invoices: [],
      showSubscriptionWarning: false,
      stripePortalUrl: '',
    }
  },

  mutations: {
    setGeneral(state, settings) {
      state.general = { ...settings }
    },

    setOrganizationDetails(state, organization) {
      state.organization = { ...organization }
    },

    setTeams(state, teams) {
      state.teams = [...teams]
    },

    setRoles(state, roles) {
      state.roles = [...roles]
    },

    setInvoices(state, invoices) {
      state.invoices = [...invoices]
    },

    setSubscriptionWarning(state) {
      state.showSubscriptionWarning = false

      setTimeout(() => {
        if (state.organization.subscription.plan !== 'trial') return

        const today = new Date().toISOString().split('T')[0]
        const trialEnd = state.organization.subscription.trialEnd.split('T')[0]
        const user = JSON.parse(localStorage.getItem('user'))

        if (today > trialEnd && user) state.showSubscriptionWarning = true
      }, 3000)
    },

    setStripePortalUrl(state, url) {
      state.stripePortalUrl = ''
      state.stripePortalUrl = url
    },
  },

  actions: {
    /*
      ROUTE /:organizationId/organization
    */
    async getOrganization({ dispatch, commit, rootState }) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/organization`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.organization) {
        commit('setOrganizationDetails', data.organization)
        commit('setSubscriptionWarning', rootState)
      }
    },

    async updateOrganization({ dispatch, commit, rootState }, updatedOrganization) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/organization`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          updatedOrganization: updatedOrganization,
          userId: rootState.user.id,
        }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) commit('showNotification', { type: 'success', message: data.success })
    },

    async deleteOrganization({ dispatch, commit, rootState }) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/organization`
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        dispatch('logout')
      }
    },

    /*
      ROUTE /:organizationId/teams/:teamId?
    */
    async getTeams({ dispatch, commit, rootState }) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/teams`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.teams) commit('setTeams', data.teams)
    },

    async addTeam({ dispatch, commit, rootState }, team) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/teams`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ team }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    async updateTeam({ dispatch, commit, rootState }, team) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/teams/${team.id}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ team }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    async deleteTeam({ dispatch, commit, rootState }, teamId) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/teams/${teamId}`
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    /*
      ROUTE /:organizationId/roles/:roleId?
    */
    async getRoles({ dispatch, commit, rootState }) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/roles`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.roles) commit('setRoles', data.roles)
    },

    async addRole({ dispatch, commit, rootState }, role) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/roles`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ role }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) commit('showNotification', { type: 'success', message: data.success })

      if (data.role) return data.role

      return false
    },

    async updateRole({ dispatch, commit, rootState }, role) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/roles/${role.id}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ role }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    async deleteRole({ dispatch, commit, rootState }, roleId) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/roles/${roleId}`
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    /*
      ROUTE /:organizationId/settings/:userId
    */
    async getUserSettings({ dispatch, commit, rootState }) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/user/settings/${rootState.user.id}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.settings) commit('setGeneral', data.settings)
    },

    async updateUserSettings({ dispatch, commit, rootState }, settings) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/user/settings/${rootState.user.id}`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ settings }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.settings) {
        commit('setGeneral', data.settings)
        commit('showNotification', {
          type: 'success',
          message: data.success,
        })
      }
    },

    /*
      ROUTE /:organizationId/subscription/invoices
    */
    async getInvoices({ dispatch, commit, rootState }) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/subscription/invoices`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.invoices) commit('setInvoices', data.invoices)
    },

    /*
      ROUTE /:organizationId/subscription/portal
    */
    async getStripePortalUrl({ dispatch, commit, rootState }) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/subscription/portal`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.portalUrl) commit('setStripePortalUrl', data.portalUrl)
    },
  },

  getters: {
    getGeneralSettings(state) {
      return state.general
    },

    getOrganization(state) {
      return state.organization
    },

    getTeams(state) {
      return state.teams
    },

    getRoles(state) {
      return state.roles
    },

    getSubscription(state) {
      return state.organization.subscription
    },

    getInvoices(state) {
      return state.invoices
    },

    getStripePortalUrl(state) {
      return state.stripePortalUrl
    },
  },
}
