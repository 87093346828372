<template>
  <div class="input__wrapper row">
    <input
      :type="type"
      :step="step"
      :placeholder="placeholder"
      :value="value"
      @input="update"
      :required="required"
      :class="{ noBorder: !border, noMargin: !margin, small: small }"
    />

    <Info v-if="info !== ''" :info="info" class="info__icon" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'

import Info from '@components/button/Info.vue'

export default {
  name: 'Input',
  components: { Info },
  emits: ['update'],
  props: {
    type: { type: String, default: 'text' },
    step: { type: Number, default: 0.1 },
    value: { type: [String, Number], default: '' },
    placeholder: { type: [String, Number], default: '' },
    clear: { type: Boolean, default: false },
    border: { type: Boolean, default: true },
    margin: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    info: { type: String, default: '' },
    small: { type: Boolean, default: false },
  },

  setup(props, { emit }) {
    const input = ref(props.value)
    const update = (e) => emit('update', e.target.value.trim())
    const clear = () => (input.value = '')

    watch(
      () => props.clear,
      (newVal) => {
        if (newVal) clear()
      }
    )

    return {
      update,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './styles.scss';

.input__wrapper {
  position: relative;
  width: 100%;

  .small {
    height: 29px;
  }

  .noBorder {
    border: none !important;

    &.small {
      height: 30px;
    }
  }

  .noMargin {
    margin: 0 !important;
  }

  .info__button__container {
    position: absolute;
    right: 7px;
    top: calc(50% - 10px);
  }
}
</style>
