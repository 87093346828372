<template>
  <div
    class="overlay__container row"
    ref="container"
    :class="{
      scroll: scroll,
      full__width: dashboardNavExpanded,
      auth: authenticated,
    }"
    @click="$emit('close')"
  >
    <transition name="grow">
      <slot></slot>
    </transition>
  </div>
</template>

<script>
import { onBeforeMount, ref, computed, onBeforeUnmount, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Overlay',
  emits: ['close'],
  props: {
    scroll: { type: Boolean, default: false },
  },
  setup(_, { emit }) {
    const store = useStore()
    const container = ref(null)
    const dashboardNavExpanded = computed(() => store.state.dashboardNav.expanded)
    const authenticated = computed(() => store.state.user.id)
    const showOverlay = computed(() => store.state.overlay.show)

    watch(showOverlay, (show) => {
      if (!show) emit('close')
    })

    watch(container, (newVal) => {
      const app = document.getElementById('app')
      app.appendChild(newVal)
    })

    onBeforeMount(() => {
      store.commit('showOverlay', true)
    })

    onBeforeUnmount(() => {
      store.commit('showOverlay', false)
    })

    return {
      container,
      dashboardNavExpanded,
      authenticated,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/media.scss';

.overlay__container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--dark-4);
  z-index: 15;
  align-items: center;
  justify-content: center;

  &.scroll {
    overflow-y: auto;
    overflow-x: hidden;
    align-items: flex-start;

    form {
      margin: 70px 0;
    }
  }
}
</style>
