export default {
  en: {
    'Key successfully updated.': 'Key successfully updated.',
    'Logged out successfully!': 'Logged out successfully!',
    'Welcome! Please verify your email address.': 'Welcome! Please verify your email address.',
    'Welcome back!': 'Welcome back!',
    'Password reset. Please check your inbox.': 'Password reset. Please check your inbox.',
    'User already verified. Please login.': 'User already verified. Please login.',
    'Email successfully verified!': 'Email successfully verified!',
    'Email available.': 'Email available.',
    'Welcome! Feel free to look around.': 'Welcome! Feel free to look around.',
    'Entry successfully added.': 'Entry successfully added.',
    'Schedule successfully updated.': 'Schedule successfully updated.',
    'Entry successfully deleted.': 'Entry successfully deleted.',
    'Organization successfully updated.': 'Organization successfully updated.',
    'Organization successfully deleted.': 'Organization successfully deleted.',
    'Team successfully created.': 'Team successfully created.',
    'Team successfully updated.': 'Team successfully updated.',
    'Team successfully deleted.': 'Team successfully deleted.',
    'Role successfully created.': 'Role successfully created.',
    'Role successfully updated.': 'Role successfully updated.',
    'Role successfully deleted.': 'Role successfully deleted.',
    'Entry successfully deleted.': 'Entry successfully deleted.',
    'Entry successfully deleted.': 'Entry successfully deleted.',
    'File successfully uploaded.': 'File successfully uploaded.',
    'User successfully registered.': 'User successfully registered.',
    'User successfully deleted.': 'User successfully deleted.',
    'Email successfully updated.': 'Email successfully updated.',
    'Permission successfully updated.': 'Permission successfully updated.',
    'Password successfully updated.': 'Password successfully updated.',
    'Note successfully updated.': 'Note successfully updated.',
    'Time successfully updated.': 'Time successfully updated.',
    'Basic data successfully updated.': 'Basic data successfully updated.',
    'Messengers successfully updated.': 'Messengers successfully updated.',
    'Position successfully updated.': 'Position successfully updated.',
    'Successfully finished our tour.': 'Successfully finished our tour.',
    'Copied to clipboard!': 'Copied to clipboard!',
    'Report successfully created.': 'Report successfully created.',
    'Report successfully updated.': 'Report successfully updated.',
    'Report successfully deleted.': 'Report successfully deleted.',

    'Something went wrong. Please try again.': 'Something went wrong. Please try again.',
    'Missing email address.': 'Missing email address.',
    'Email already associated with an organization.':
      'Email already associated with an organization.',
    'User does not exist.': 'User does not exist.',
    'Please verify your account. An email has been sent.':
      'Please verify your account. An email has been sent.',
    'Username and password do not match.': 'Username and password do not match.',
    'Verification failed. Please try again.': 'Verification failed. Please try again.',
    'End cannot be before start date.': 'End cannot be before start date.',
    'Please cancel your subscription before proceeding.':
      'Please cancel your subscription before proceeding.',
    'Team with that name already exists.': 'Team with that name already exists.',
    'Team has associated users.': 'Team has associated users.',
    'Role with that name already exists.': 'Role with that name already exists.',
    'Role has associated users.': 'Role has associated users.',
    'Missing filter parameters. Please try again.': 'Missing filter parameters. Please try again.',
    'Reached max. amount of reports in your plan.': 'Reached max. amount of reports in your plan.',
    'User already part of an organization.': 'User already part of an organization.',
    'No permission to delete this user.': 'No permission to delete this user.',
    'Min. 8 chars, uppercase, number & special char.':
      'Min. 8 chars, uppercase, number & special char.',
    'Please login first.': 'Please login first.',
    'Permission required.': 'Permission required.',
    'Missing api key.': 'Missing API key.',
    'Invalid api key.': 'Invalid API key.',
    'Email addresses do not match.': 'Email addresses do not match.',
    'Passwords do not match.': 'Passwords do not match.',
    'Invalid file type (max. size 10mb.)': 'Invalid file type (max. size 10mb.)',
    'Please fill out all required fields.': 'Please fill out all required fields.',
    'Your verification code has expired.': 'Your verification code has expired.',
    'Email already taken.': 'Email already taken.',
    'Existing entry for date in range.': 'Existing entry for date in range.',
    'Logged out automatically.': 'Logged out automatically.',
  },

  de: {
    'Key successfully updated.': 'Schlüssel erfolgreich aktualisiert.',
    'Logged out successfully!': 'Benutzer erfolgreich abgemeldet!',
    'Welcome! Please verify your email address.':
      'Willkommen! Bitte bestätige deine E-Mail-Adresse.',
    'Welcome back!': 'Willkommen zurück!',
    'Password reset. Please check your inbox.':
      'Passwort zurückgesetzt. Bitte überprüfe dein Postfach.',
    'User already verified. Please login.': 'Benutzer bereits verifiziert. Bitte melde dich an.',
    'Email successfully verified!': 'E-Mail erfolgreich verifiziert!',
    'Email available.': 'E-Mail verfügbar.',
    'Welcome! Feel free to look around.': 'Willkommen! Schau dich ruhig um.',
    'Entry successfully added.': 'Eintrag erfolgreich hinzugefügt.',
    'Schedule successfully updated.': 'Zeitplan erfolgreich aktualisiert.',
    'Entry successfully deleted.': 'Eintrag erfolgreich gelöscht.',
    'Organization successfully updated.': 'Organisation erfolgreich aktualisiert.',
    'Organization successfully deleted.': 'Organisation erfolgreich gelöscht.',
    'Team successfully created.': 'Team erfolgreich erstellt.',
    'Team successfully updated.': 'Team erfolgreich aktualisiert.',
    'Team successfully deleted.': 'Team erfolgreich gelöscht.',
    'Role successfully created.': 'Rolle erfolgreich erstellt.',
    'Role successfully updated.': 'Rolle erfolgreich aktualisiert.',
    'Role successfully deleted.': 'Rolle erfolgreich gelöscht.',
    'File successfully uploaded.': 'Datei erfolgreich hochgeladen.',
    'User successfully registered.': 'Benutzer erfolgreich registriert.',
    'User successfully deleted.': 'Benutzer erfolgreich gelöscht.',
    'Email successfully updated.': 'E-Mail erfolgreich aktualisiert.',
    'Permission successfully updated.': 'Berechtigung erfolgreich aktualisiert.',
    'Password successfully updated.': 'Passwort erfolgreich aktualisiert.',
    'Note successfully updated.': 'Notiz erfolgreich aktualisiert.',
    'Time successfully updated.': 'Zeit erfolgreich aktualisiert.',
    'Basic data successfully updated.': 'Basisdaten erfolgreich aktualisiert.',
    'Messengers successfully updated.': 'Messenger erfolgreich aktualisiert.',
    'Position successfully updated.': 'Position erfolgreich aktualisiert.',
    'Successfully finished our tour.': 'Unsere Tour erfolgreich beendet.',
    'Copied to clipboard!': 'In die Zwischenablage kopiert!',
    'Report successfully created.': 'Report erfolgreich erstellt.',
    'Report successfully updated.': 'Report erfolgreich aktualisiert.',
    'Report successfully deleted.': 'Report erfolgreich gelöscht.',

    'Something went wrong. Please try again.':
      'Etwas ist schief gelaufen. Bitte versuche es erneut.',
    'Missing email address.': 'Fehlende E-Mail-Adresse.',
    'Email already associated with an organization.':
      'E-Mail bereits mit einer Organisation verknüpft.',
    'User does not exist.': 'Benutzer existiert nicht.',
    'Please verify your account. An email has been sent.':
      'Bitte verifiziere dein Konto. Eine E-Mail wurde gesendet.',
    'Username and password do not match.': 'Benutzername und Passwort stimmen nicht überein.',
    'Verification failed. Please try again.':
      'Verifizierung fehlgeschlagen. Bitte versuche es erneut.',
    'End cannot be before start date.': 'Ende kann nicht vor dem Startdatum liegen.',
    'Please cancel your subscription before proceeding.':
      'Bitte kündige dein Abonnement, bevor du fortfährst.',
    'Team with that name already exists.': 'Ein Team mit diesem Namen existiert bereits.',
    'Team has associated users.': 'Team hat zugeordnete Nutzer.',
    'Role with that name already exists.': 'Eine Rolle mit diesem Namen existiert bereits.',
    'Role has associated users.': 'Rolle hat zugeordnete Nutzer.',
    'Missing filter parameters. Please try again.':
      'Fehlende Filterparameter. Bitte versuche es erneut.',
    'Reached max. amount of reports in your plan.':
      'Maximale Anzahl an Reports in deinem Plan erreicht.',
    'User already part of an organization.': 'Benutzer ist bereits Teil einer Organisation.',
    'No permission to delete this user.': 'Keine Berechtigung diesen Nutzer zu löschen.',
    'Min. 8 chars, uppercase, number & special char.':
      'Mind. 8 Zeichen, Großbuchstaben, Zahl & Sonderzeichen.',
    'Please login first.': 'Bitte melde dich zuerst an.',
    'Permission required.': 'Berechtigung erforderlich.',
    'Missing api key.': 'Fehlender API-Schlüssel.',
    'Invalid api key.': 'Ungültiger API-Schlüssel.',
    'Email addresses do not match.': 'E-Mail-Adressen stimmen nicht überein.',
    'Passwords do not match.': 'Passwörter stimmen nicht überein.',
    'Invalid file type (max. size 10mb.)': 'Ungültiger Dateityp (max. Größe 10 MB.)',
    'Please fill out all required fields.': 'Bitte fülle alle erforderlichen Felder aus.',
    'Your verification code has expired.': 'Dein Bestätigungscode ist abgelaufen.',
    'Email already taken.': 'E-Mail bereits vergeben.',
    'Existing entry for date in range.': 'Eintrag für Datum vorhanden.',
    'Logged out automatically.': 'Automatisch abgemeldet.',
  },
}
