export default {
  'Add Report': 'Add Report',
  'Edit Report': 'Edit Report',
  'api-key-instruction':
    'API keys will only be stored on the server and can be validated by sending a request to our validation endpoint.',
  'Add Component': 'Add Component',
  'Report component': 'Report component',
  Url: 'Url',
  Type: 'Type',
  Width: 'Width',
  'Request Headers': 'Request Headers',
  'Request Params': 'Request Params',
  'Delete Report?': 'Delete Report?',
  Description: 'Description',
  Date: 'Date',
  'Date From': 'Date From',
  'Date To': 'Date To',
  'Date From Comp.': 'Date From Comp.',
  'Date To Comp.': 'Datum bis vergl.',
  'Date Range': 'Date Range',
  'Date Range Comp.': 'Date Range Comp.',
  Apply: 'Apply',
  'Existing Filter Lists': 'Existing Filter Lists',
  'Filter Lists': 'Filter Lists',
  Lists: 'Lists',
  'Manage your Filter Lists': 'Manage your Filter Lists',
  'X-Axis Title': 'X-Axis Title',
  'Y-Axis Title': 'Y-Axis Title',
  'Y-Axis Symbol': 'Y-Axis Symbol',
  Legend: 'Legend',
  'X-Axis Labels': 'X-Axis Labels',
  'Y-Axis Labels': 'Y-Axis Labels',
  'Data Labels': 'Data Labels',
  'Stack Labels': 'Stack Labels',
  'Inside Labels': 'Inside Labels',
  'Line Marker': 'Line Marker',
  'Show Footer': 'Show Footer',
  'Small Rows': 'Small Rows',
  'Configure columns': 'Configure columns',
}
