export default {
  state() {
    return {
      users: [],
      editUser: {},
    }
  },

  mutations: {
    setUsers(state, users) {
      state.users = users.sort((a, b) => {
        return ('' + a.email).localeCompare(b.email)
      })
    },

    setEditUser(state, user) {
      state.editUser = { ...user }
    },
  },

  actions: {
    /*
      ROUTE /:organizationId/user/:userId?
    */
    async getUser({ dispatch, commit, rootState }, userId) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/user/${userId}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.user) return data.user

      return null
    },

    async addUser({ dispatch, commit, rootState }, user) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/user`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    async updateUser({ dispatch, commit, rootState }, payload) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/user/${payload.type}/${payload.id}`
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ payload }),
      })

      const data = await response.json()

      if (!response.ok || data.error)
        commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        return true
      }

      return false
    },

    async deleteUser({ dispatch, commit, rootState }, userId) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/user/${userId}`
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.success) {
        commit('showNotification', { type: 'success', message: data.success })
        if (userId === rootState.user.id) dispatch('logout')
        return true
      }

      return false
    },

    /*
      ROUTE /:organizationId/users
    */
    async getUsers({ dispatch, commit, rootState }) {
      await dispatch('validateAccessToken')

      const url = `${rootState.url}/${rootState.user.organization}/users`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rootState.token}`,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (!response.ok || data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error || 'Something went wrong, please try again.',
        })

      if (data.users) commit('setUsers', data.users)
    },
  },

  getters: {
    getUsers(state) {
      return state.users
    },

    getUsersEmail(state) {
      let users = []
      for (let user of state.users || []) {
        users.push({
          id: user._id,
          email: user.email,
          team: user.team?._id || null,
          role: user.role?._id || null,
          hr: user.permission.hr,
        })
      }

      return users
    },

    getEditUser(state) {
      return state.editUser
    },
  },
}
