<template>
  <Overlay @close="$emit('close')">
    <Form
      headline="Select language"
      :showBtns="false"
      :showInputs="false"
      :style="{ width: '300px' }"
    >
      <div class="languages__popup__container">
        <div
          class="language highlight"
          :class="{ active: language === 'en' }"
          @click="updateLanguage('en')"
        >
          {{ t('English') }}
        </div>

        <div
          class="language highlight"
          :class="{ active: language === 'de' }"
          @click="updateLanguage('de')"
        >
          {{ t('German') }}
        </div>
      </div>
    </Form>
  </Overlay>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Overlay from '@components/overlay/Overlay'
import Form from '@components/form/Form.vue'

export default {
  name: 'Languages',
  emits: ['close'],
  components: { Overlay, Form },
  setup(_, { emit }) {
    const store = useStore()
    const language = computed(() => store.state.language)

    const updateLanguage = (lang) => {
      store.commit('setLanguage', lang)
      emit('close')
    }

    return {
      language,
      updateLanguage,
    }
  },
}
</script>

<style lang="scss" scoped>
.languages__popup__container {
  .language {
    margin: 0 auto;
    padding: 10px 15px;
    border-radius: 15px;
    width: 100px;
    text-align: center;

    &:first-of-type {
      margin-bottom: 5px;
    }
  }
}
</style>
