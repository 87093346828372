import store from '../../../store'

export default [
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@modules/settings/Settings.vue'),
    meta: { authenticated: true, title: 'Sharedex | Settings' },
    children: [
      {
        path: 'general',
        name: 'General',
        component: () => import('@modules/settings/views/general/index.vue'),
      },
      {
        path: 'organization',
        name: 'Organization',
        meta: { permissions: ['admin'] },
        component: () => import('@modules/settings/views/organization/index.vue'),
        beforeEnter: async () => {
          if (!store.state.settings.organization.name) await store.dispatch('getOrganization')
        },
      },
      {
        path: 'teams',
        name: 'Teams',
        meta: { permissions: ['admin', 'hr'] },
        component: () => import('@modules/settings/views/teams/index.vue'),
      },
      {
        path: 'roles',
        name: 'Roles',
        meta: { permissions: ['admin', 'hr'] },
        component: () => import('@modules/settings/views/roles/index.vue'),
      },
      {
        path: 'subscription',
        name: 'Subscription',
        meta: { permissions: ['admin'] },
        component: () => import('@modules/settings/views/subscription/index.vue'),
        beforeEnter: async () => {
          if (!store.state.settings.organization.name) await store.dispatch('getOrganization')
        },
      },
      {
        path: 'new-user',
        name: 'New',
        meta: { permissions: ['admin', 'hr'] },
        component: () => import('@modules/settings/views/newUser/index.vue'),
      },
      {
        path: 'profile/:id',
        name: 'Edit profile',
        meta: { permissions: ['user'] },
        component: () => import('@modules/settings/views/editUser/index.vue'),
        beforeEnter: async (to) => {
          if (!store.state.users.editUser || !store.state.users.editUser._id) {
            const user = await store.dispatch('getUser', to.params.id)
            store.commit('setEditUser', user)
          }
        },
      },
      {
        path: 'edit-user/:id',
        name: 'Edit User',
        meta: { permissions: ['admin', 'hr'] },
        component: () => import('@modules/settings/views/editUser/index.vue'),
        beforeEnter: async (to) => {
          if (!store.state.users.editUser || !store.state.users.editUser._id) {
            const user = await store.dispatch('getUser', to.params.id)
            store.commit('setEditUser', user)
          }
        },
      },
    ],
  },
]
