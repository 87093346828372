import store from '../../store/index.js'

export const hasPermission = (permissions = [], params = {}) => {
  if (permissions.length <= 0 || !store.state.user.permission) return true

  for (let permission of permissions) {
    switch (permission) {
      case 'admin':
        if (store.state.user.permission.admin) return true
        break

      case 'hr':
        if (store.state.user.permission.hr) return true
        break

      case 'manager':
        if (!params || !params.manager) break
        if (params.manager === store.state.user.id) return true

      case 'user':
        if (!params || !params.id) break
        if (params.id === store.state.user.id) return true
    }
  }

  return false
}

export const hasPlan = (plans = [], params = {}) => {
  if (plans.length <= 0) return true

  const plan = store.state.settings.organization.subscription?.plan || 'individual'
  const restricted = []
  const allowed = []

  plans.forEach((p) => {
    if (p.includes('!')) restricted.push(p.replace('!', ''))
    else allowed.push(p)
  })

  if (restricted.includes(plan)) return false

  if (allowed.length > 0) {
    if (allowed.includes(plan)) return true
    else return false
  }

  return true
}
