<template>
  <router-view v-slot="{ Component }" class="router__view">
    <component :is="Component" />
  </router-view>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'BaseRouter',
  props: ['transition'],
  setup() {
    const store = useStore()
    const delay = ref(0)
    const initial = computed(() => {
      if (store.state.screen.orientation === 'landscape') return { opacity: 0 }
      else return { opacity: 0, x: 50 }
    })
    const visible = computed(() => {
      if (store.state.screen.orientation === 'landscape') return { opacity: 1 }
      else return { opacity: 1, x: 0 }
    })

    return {
      store,
      delay,
      initial,
      visible,
    }
  },
}
</script>

<style lang="scss" scoped></style>
